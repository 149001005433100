import React, { useEffect, useState } from "react";
import { Formik, Field, Form as FormikForm } from "formik";
import { Form, Input, Dropdown, Upload, Row, Col, Button, Switch, Select, notification, } from "antd";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toggleNewDialog } from "../store/stateSlice";
import {
    postAsset,
    putAsset,
    getAsset,
    getAssetCategory,
} from "../store/dataSlice";

import { DatePicker, Space } from "antd";
import moment from "moment";
const { TextArea } = Input;


const AssetForm = ({ handleRefresh }) => {
    const [showSaveNext, setShowSaveNext] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [edit, setEdit] = useState(false);
    const dispatch = useDispatch();
    const selectedAsset = useSelector(
        (state) => state.asset.state.selectedAsset
    );

    const categoryList = useSelector((state) => state?.asset?.data?.categoryList?.data);
    //console.log("categoryType",categoryType);

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const { Option } = Select;




    const validationSchema = Yup.object({
        CATEGORY_ID: Yup.string().required("Required"),
        STATUS: Yup.boolean().required("Required"),
        DESCRIPTION: Yup.string().required("Required"),
        QTY: Yup.string().required("Required"),
        ASSET_NAME: Yup.string().required("Required"),


        // status: Yup.boolean().required("Required"),
    });


    useEffect(() => {
        if (selectedAsset) {
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [selectedAsset]);
    useEffect(() => {
       dispatch(getAssetCategory())
    }, []);

    // useEffect(() => {
    //     const fetchGSTType = async () => {
    //         try {
    //             const response = await apigetGstType();

    //             if (response.status === 200) {
    //                 const responseData = response.data;
    //                 //console.log(responseData);
    //             } else {
    //                 console.error();
    //             }
    //         } catch (err) {
    //             //console.log(err);
    //         }
    //     };

    //     fetchGSTType();
    // }, []);

    const initialValues = selectedAsset || {

        CATEGORY_ID: "",
        STATUS: true,
        DESCRIPTION: "",
        QTY: "",
        ASSET_NAME: "",
        CATEGORY_NAME:"",



    };



    const handleCancel = () => {
        dispatch(toggleNewDialog(false));
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        //console.log("Form values:", values);
        try {
            const action = edit
                ? await dispatch(putAsset(values))
                : await dispatch(postAsset(values));
            
            if (action.payload.code < 300) {
                dispatch(getAsset());
                
                dispatch(toggleNewDialog(false));
                api.success({
                    message: "Form Submitted Successfully.",
                });



            } else {
                console.error(
                    "Error occurred during form submission:",
                    action.payload.error
                );
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <>
            <div className="mt-4">
                {/* <h2 className="mb-2">Add Distributor</h2> */}
                {contextHolder}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ values, touched, errors, handleChange, setFieldValue, submitForm, handleBlur }) => (
                        <FormikForm>
                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={touched.CATEGORY_ID && errors.CATEGORY_ID ? errors.CATEGORY_ID : ""}
                                        validateStatus={touched.CATEGORY_ID && errors.CATEGORY_ID ? "error" : undefined}
                                    >
                                        <span className="text-xs">Category Name</span>
                                        <Select
                                            showSearch
                                            placeholder="Select Category name"
                                            onChange={(value) => setFieldValue("CATEGORY_ID", value)} 
                                            value={values.CATEGORY_ID}
                                            className="h-[40px] rounded"
                                        >
                                            {categoryList?.map((category) => (
                                                <Option
                                                    key={category.ID} 
                                                    value={category.ID} 
                                                    className="text-gray-400"
                                                >
                                                    {category.NAME} 
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.DESCRIPTION && errors.DESCRIPTION
                                                ? errors.DESCRIPTION
                                                : ""
                                        }
                                        validateStatus={
                                            touched.DESCRIPTION && errors.DESCRIPTION
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Description</span>

                                        <TextArea
                                            rows={4}
                                            placeholder="You Can Add Maximum 512 Characters.."
                                            maxLength={6000}
                                            value={values.DESCRIPTION}
                                            onChange={(e) => setFieldValue("DESCRIPTION", e.target.value)}
                                        />

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.QTY && errors.QTY
                                                ? errors.QTY
                                                : ""
                                        }
                                        validateStatus={
                                            touched.QTY && errors.QTY
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Quantity</span>

                                        <Input
                                            name="QTY"
                                            placeholder="QTY"
                                            value={values.QTY}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />

                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={23}>
                                    <Form.Item
                                        className="flex flex-col"
                                        help={
                                            touched.ASSET_NAME && errors.ASSET_NAME
                                                ? errors.ASSET_NAME
                                                : ""
                                        }
                                        validateStatus={
                                            touched.ASSET_NAME && errors.ASSET_NAME
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs">Asset Name</span>

                                        <Input
                                            name="ASSET_NAME"
                                            placeholder="ASSET_NAME"
                                            value={values.ASSET_NAME}
                                            onChange={handleChange}
                                            // onBlur={handleBlur}
                                            className="!rounded"
                                            style={{ padding: "8px" }}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>





                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        className="flex flex-col "
                                        help={
                                            touched.STATUS && errors.STATUS
                                                ? errors.STATUS
                                                : ""
                                        }
                                        validateStatus={
                                            touched.STATUS && errors.STATUS
                                                ? "error"
                                                : undefined
                                        }
                                    >
                                        <span className="text-xs ">Status</span>

                                        {/* <Switch
                                            checked={values.STATUS}
                                            onChange={(checked) => setFieldValue("STATUS", checked)}
                                            defaultChecked
                                            style={{ padding: "8px" }}

                                        /> */}
                                        <div style={{ marginTop: 8 }}>
                                            <Switch
                                                checked={values.STATUS}
                                                onChange={(checked) => setFieldValue("STATUS", checked)}
                                                defaultChecked
                                                style={{ width: 50 }}
                                            />
                                        </div>



                                        {/* setFieldValue is a function provided by Formik, a popular library for managing
                                         forms in React. It allows you to programmatically set the value of a field in the
                                          form state. This is particularly useful when you need to update the value of a field 
                                          based on some external input or event, rather than user input directly within a form field. */}


                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item className="flex justify-end">
                                        <Button
                                            onClick={handleCancel}
                                            type="primary"
                                            className="mr-4"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        // disabled={isSubmitting}
                                        >
                                            {edit ? "Update" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AssetForm;

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../../view/Dashboard/index";

import Setting from "../../view/Setting/components/Setting";
import Distributor from "../../view/Distributor/index";
import Organisation from "../../view/Organisation/index";
import Designation from "../../view/Designation/index";
import Item from "../../view/Item/index";
import Form from "../../view/Form/index";
import Asset from "../../view/Asset/index";
import AccountGroup from "../../view/AccountGroup";
import Notification from "../../view/Notification";
import Price from "../../view/Price/index";
import PriceDetails from "../../view/Price/Components/PriceDetails";

import Login from "../../view/login/Login";
import Employee from "../../view/Employee";
import Branch from "../../view/Branch";
import ItemCategory from "../../view/ItemCategory";
import Role from "../../view/Role";
import Unit from "../../view/Unit";
import Task from "../../view/Task";

import State from "../../view/State/index";
import Cart from "../../view/Cart/index";
import Order from "../../view/Order/index";
import OrderDetails from "../../view/Order/components/OrderDetails";
import Department from "../../view/Department/index";
import Client from "../../view/Client/index";

import Employeetarget from "../../view/Employeereport/index";

import EmployeeType from "../../view/EmployeeType";
import AssetCategory from "../../view/AssetCategory";

import Customer from "../../view/Customer";
import CustomerDetails from "../../view/Customer/components/CustomerDetails";

import Location from "../../view/Location";

import Attendance from "../../view/Attendence/index";
import Expense from "../../view/Expense/index";

import Account from "../../view/Account/index";

import EOMMaster from "../../view/EOMMaster/index";
import AssetRequest from "../../view/AssetRequest/index";
import EmployeeTargetMapping from "../../view/EmployeeTargetMapping/index";
import EmployeeTargetInnerTable from "../../view/EmployeeTargetMapping/Components/EmployeeTargetInnerTable";
import LeaveType from "../../view/LeaveType";
import ExpenseType from "../../view/ExpenseType";
import Holiday from "../../view/Holiday";
import RoleAssign from "../../view/RoleAssign";

import Transaction from "../../view/Transaction";
import Service from "../../view/Service";
import Report from "../../view/Report";
import Leave from "../../view/Leave";
import TaskMaster from "../../view/TaskMaster";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      <Route path="/setting" element={<Setting />}></Route>
      <Route path="/distributor" element={<Distributor />}></Route>

      <Route path="/employee" element={<Employee />}></Route>
      <Route path="/branch" element={<Branch />}></Route>
      <Route path="/itemCategory" element={<ItemCategory />}></Route>
      <Route path="/role" element={<Role />}></Route>
      <Route path="/unit" element={<Unit />}></Route>
      <Route path="/task" element={<Task />}></Route>
      <Route path="/taskMaster" element={<TaskMaster />}></Route>
      <Route path="/transaction" element={<Transaction />}></Route>
      <Route path="/organisation" element={<Organisation />}></Route>
      <Route path="/designation" element={<Designation />}></Route>
      <Route path="/item" element={<Item />}></Route>
      <Route path="/form" element={<Form />}></Route>
      <Route path="/price" element={<Price />}></Route>
      <Route path="/pricedetail" element={<PriceDetails />}></Route>
      <Route path="/accountgroup" element={<AccountGroup />}></Route>
      <Route path="/notification" element={<Notification />}></Route>

      <Route path="/state" element={<State />}></Route>
      <Route path="/cart" element={<Cart />}></Route>
      <Route path="/order" element={<Order />}></Route>
      <Route path="/order/details" element={<OrderDetails />}></Route>
      <Route path="/department" element={<Department />}></Route>
      <Route path="/asset" element={<Asset />}></Route>

      <Route path="/employeetargetreport" element={<Employeetarget />}></Route>

      <Route path="/employeeType" element={<EmployeeType />}></Route>
      <Route path="/assetcategory" element={<AssetCategory />}></Route>

      <Route path="/customer" element={<Customer />}></Route>
      <Route path="/customer/details" element={<CustomerDetails />}></Route>

      <Route path="/eom" element={<EOMMaster />}></Route>
      <Route path="/assetrequest" element={<AssetRequest />}></Route>
      <Route path="/Location" element={<Location />}></Route>

      <Route path="/attendance" element={<Attendance />}></Route>
      <Route path="/expense" element={<Expense />}></Route>

      <Route path="/account" element={<Account />}></Route>

      <Route path="/employeetarget" element={<EmployeeTargetMapping />}></Route>
      <Route
        path="/employeetargetinner"
        element={<EmployeeTargetInnerTable />}
      ></Route>

      <Route path="/leaveType" element={<LeaveType />}></Route>
      <Route path="/leave" element={<Leave />}></Route>

      <Route path="/expenseType" element={<ExpenseType />}></Route>
      <Route path="/holiday" element={<Holiday />}></Route>
      <Route path="/roleAssign" element={<RoleAssign />}></Route>
      <Route path="/service" element={<Service />}></Route>
      <Route path="/report" element={<Report />}></Route>

      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AppRoutes;

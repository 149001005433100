import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Typography, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { apiLogin } from "../../services/AuthService";
import { useDispatch } from "react-redux";
import { getMenu } from "../../components/Sidebar/store/dataSlice";
import { persistor } from "../../store/index";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onFinish = async () => {
    try {
      setLoading(true);

      localStorage.clear();
      persistor.flush();
      persistor.purge();

      const response = await apiLogin({ username, password });

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData && responseData.data && responseData.data.length > 0) {
          const user = responseData.data[0].token;
          localStorage.setItem("token", user);
          const userData = responseData.data[0].UserData;
          localStorage.setItem("UserData", JSON.stringify(userData));

          // Save the ROLE_ID of the first role in localStorage
          if (userData[0].ROLE_DETAILS && userData[0].ROLE_DETAILS.length > 0) {
            const firstRoleId = userData[0].ROLE_DETAILS[0].ROLE_ID;
            localStorage.setItem("ROLE_ID", firstRoleId);
          }

          //Fetch Menu Data
          const roleId = localStorage.getItem("ROLE_ID");
          console.log(roleId);
          const menuResponse = await dispatch(
            getMenu({ ROLE_ID: roleId })
          ).unwrap();

          console.log(menuResponse);
          const menuData = menuResponse.data;

          const sequenceOneItem = menuData.find((item) => item.SEQ_NO === 1);

          if (sequenceOneItem) {
            console.log(sequenceOneItem);
            let targetLink = sequenceOneItem.link;

            console.log(targetLink);

            if (targetLink === "#") {
              const firstChildLink =
                sequenceOneItem.children && sequenceOneItem.children.length > 0
                  ? sequenceOneItem.children[2].link
                  : null;
              if (firstChildLink) {
                targetLink = firstChildLink;
              } else {
                api.error({
                  message: "Error",
                  description: "No accessible child links found.",
                });
                setLoading(false);
                return;
              }
            }
            api.success({
              message: "Login Successfully",
            });

            setLoading(false);
            navigate(targetLink);
            window.location.href = targetLink;
          } else {
            api.error({
              message: "Error",
              description: "Menu item with sequence 1 not found.",
            });
            setLoading(false);
          }
        } else {
          api.error({
            message: "Error",
            description: response.data.message || "Unauthorized User.",
          });
          setLoading(false);
        }
      } else {
        api.error({
          message: "Error",
          description: "Unexpected error occurred.",
        });
      }
    } catch (err) {
      setLoading(false);
      api["error"]({
        message: "Error",
        description: err.message,
      });
    }
  };

  return (
    <div className="appBg">
      {contextHolder}
      <div className="loginForm">
        <h2 className="text-2xl font-bold mb-4 flex">
          Welcome <p className="text-3xl mt-[-6px]">&#128075;</p>
        </h2>
        <p className="text-gray-500 mb-4">Please Login to continue&#128071;</p>
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item className="!mb-3">
            <Button
              type="primary"
              htmlType="submit"
              block
              className="btn"
              loading={loading}
              disabled={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox>Remember me</Checkbox>
          </div>
          <div className="text-left text-xs text-blue-600">
            <Link>Forgot Password?</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import axios from "axios";
// import appConfig from '../configs/app.config'
// import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '../constants/api.constant'
// import { PERSIST_STORE_NAME } from '../constants/app.constant'
// import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401];


const BaseService = axios.create({
  timeout: 60000,
  baseURL:
    "https://hrm.vkrepo.in/",
    // "https://cdff-2409-40c2-104d-46b0-b057-171c-93ad-4b75.ngrok-free.app"
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem("token");
    // //console.log("rawPersistData", rawPersistData);

    // //console.log("persistData", rawPersistData);

    let accessToken = rawPersistData ? rawPersistData : null;

    // //console.log(accessToken);
    // if (accessToken) {
    config.headers["token"] = `${accessToken}`;
    // }
    config.headers["apikey"] =
      // "hjh4653dsiivy457468asdfe";

      // "2gtRe4765H45F9F2f8jGfdhN";
      "w3rvs587hbdt9ugs6YvD3K0bgDtPl";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    if (response.data.code === 403) {
      localStorage.removeItem("UserData");
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    //console.log(response.data);
    return response;
  },
  (error) => {
    const { response } = error;

    // if (response && unauthorizedCode.includes(response.status)) {
    //   store.dispatch(onSignOutSuccess());
    // }

    return Promise.reject(error);
  }
);

export default BaseService;
